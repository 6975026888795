// import { useRouter } from "next/router";
import React, { useState } from "react";
// import Select from 'react-select'
import { useFormik } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isMobile } from "../components/DeviceType";
import { useOrgTheme } from "../context/ThemeContext";
import { LANGUAGE } from "../enums/language.enum";
import { CONTRACT_TYPE } from "../enums/contractType.enum";
import { getNameValidation } from "./LeadForm/FormValidation";
import { formatCurrency } from "../utils/common.function.utils";

export enum LEAD_FORM_POPUP {
  SIDEBAR_POPUP = "sidebar_popup",
  CENTER_POPUP = "center_popup",
}

const LeadFormModal = (props: any) => {
  const { tabToSelect } = props;
  const { getOrgName, getOrgSettings, isDifferentEnergies } = useOrgTheme();
  let {
    lead_form_layout: leadFormStyle,
    is_phone_number_required: isPhoneRequired,
    show_co_signer,
    self_site_survey_enabled: showSiteSurveyField,
    discount_amount: selfSiteSurveyDiscount = 200,
  } = getOrgSettings();

  isPhoneRequired = tabToSelect === CONTRACT_TYPE.LEASE || isPhoneRequired;

  const [showCoSigner, setShowCoSigner] = useState(false);
  const showSidebarPopup = leadFormStyle === LEAD_FORM_POPUP.SIDEBAR_POPUP;

  const validCountryCodePattern = /^(1|91)$/;

  const phoneValidation = () => {
    const validation: any = yup
      .string()
      .test("phone", "Phone number is required", (value) => {
        if (!value && isPhoneRequired) {
          return false;
        }
        return true;
      })
      .test("phone", "Invalid Phone number", (value) => {
        if (!value) {
          return true;
        }
        if (validCountryCodePattern.test(value) && !isPhoneRequired) {
          return true;
        }
        return /^(?:([1])\d{10}|([9][1][6789])\d{9})$/.test(value);
      });

    return validation;
  };

  let validationSchema = yup.object({
    first_name: getNameValidation("first"),
    last_name: getNameValidation("last"),
    phone: phoneValidation(),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: props.userData?.first_name || "",
      last_name: props.userData?.last_name || "",
      phone: props.userData?.phone || "",
      email: props.userData?.email || "",
      language: LANGUAGE.ENGLISH,
      survey: "1",
    },
    validationSchema: validationSchema,
    onSubmit: (formData: any) => {
      const dataToSave = { ...formData, ...coSignerForm.values };
      if (
        (!isPhoneRequired && !dataToSave.phone) ||
        (dataToSave?.phone && validCountryCodePattern.test(dataToSave.phone))
      ) {
        delete dataToSave.phone;
      }
      if (!showCoSigner) {
        dataToSave.co_signer_name = null;
        dataToSave.co_signer_email = null;
      }
      props.setContactForm(dataToSave);
    },
  });

  const coSignerForm = useFormik({
    initialValues: {
      co_signer_name: "",
      co_signer_email: "",
    },
    validationSchema: yup.object({
      co_signer_name: yup
        .string()
        .required("Co Signer Name is required")
        .matches(/^[A-Za-z]+$/, "Please enter valid First Name"),
      co_signer_email: yup
        .string()
        .required("Co Signer Email is required")
        .email("Invalid Co Signer email address"),
    }),
    onSubmit: (formData: any) => {
      console.log(formData);
    },
  });

  function setPhoneValue(val: string) {
    formik.setFieldValue("phone", val);
  }

  return (
    <div
      title="Lead form"
      className={`lead-form-dialog leadformCustom ${
        isMobile ? "mobileView" : ""
      } ${showSidebarPopup ? "isSideBarPopup" : ""} `}
    >
      <div className="sticky px-4 md:px-6 py-4 md:pt-5 md:pb-3 top-0 bg-white z-10 gap-2 ">
        <h2 className=" text-xl font-interSemiBold text-titleColor mr-4 md:mr-6 leading-tight md:leading-snug">
          Provide your details below to unlock your customized solar quote.
        </h2>

        {tabToSelect === CONTRACT_TYPE.LEASE ? (
          <p className="text-sm text-graydark150 pt-1">
            <span className="font-interMedium">*Note:</span> Phone number is
            required.
          </p>
        ) : (
          ""
        )}
        <span className="absolute right-1 top-5 md:right-2 md:top-5">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-mt-2 h-7 w-7 text-gray-300 hover:text-titleColor cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => {
              props.closeContactModal();
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
      <form onSubmit={formik.handleSubmit} className="w-full grid ">
        <div className="lead-form-dialog__body px-6 pt-3 pb-4">
          <div>
            <div className="grid md:grid-cols-2 gap-4 xxs:gap-6 mb-4">
              <div className="relative col-span-2 2md:col-span-1">
                <input
                  type="text"
                  placeholder="First Name"
                  {...formik.getFieldProps("first_name")}
                  name="first_name"
                  title="Enter First Name"
                  className=" text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none  p-3 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <span className="absolute  -bottom-4  block text-xs text-red-600">
                    {formik.errors.first_name}
                  </span>
                ) : null}
              </div>
              <div className="relative col-span-2 2md:col-span-1">
                <input
                  type="text"
                  placeholder="Last Name"
                  {...formik.getFieldProps("last_name")}
                  name="last_name"
                  title="Enter Last Name"
                  className=" text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white focus:outline-none p-3 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <span className="absolute  -bottom-4  block text-xs text-red-600">
                    {formik.errors.last_name}
                  </span>
                ) : null}
              </div>
              <div
                className={` relative col-span-2  text-sm xxs:text-base text-bodyColor ${
                  showSidebarPopup ? " 2md:col-span-2" : " 2md:col-span-1"
                }`}
              >
                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  autoFormat={false}
                  countryCodeEditable={false}
                  placeholder="Phone No."
                  country={"us"}
                  onlyCountries={["us", "in"]}
                  value={formik.values.phone}
                  onChange={(val) => {
                    setPhoneValue(val);
                  }}
                  onBlur={formik.handleBlur("phone")}
                />
                {!isPhoneRequired && (
                  <span className="absolute h-full top-0 flex items-center text-sm text-gray-300 right-1">
                    (Optional)
                  </span>
                )}
                {formik.touched.phone && formik.errors.phone ? (
                  <span className="absolute  -bottom-4  block text-xs text-red-600">
                    {formik.errors.phone}
                  </span>
                ) : null}
              </div>
              <div
                className={`relative col-span-2  ${
                  showSidebarPopup ? " 2md:col-span-2" : " 2md:col-span-1"
                }`}
              >
                <input
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,14}$"
                  placeholder="E-mail"
                  {...formik.getFieldProps("email")}
                  name="email"
                  title="Enter Email"
                  className=" text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white  focus:outline-none  p-3 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                />
                {formik.touched.email && formik.errors.email ? (
                  <span className="absolute  -bottom-4  block text-xs text-red-600">
                    {formik.errors.email}
                  </span>
                ) : null}
              </div>

              {show_co_signer && (
                <div className="relative col-span-2 2md:col-span-2 flex flex-col gap-2">
                  <label className="co-signer-field inline-flex flex-row items-center gap-2 mb-2 w-content">
                    <span className="relative">
                      <span className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={showCoSigner}
                          onChange={() => {
                            setShowCoSigner((prev) => !prev);
                          }}
                        />
                        <span className="check"></span>
                      </span>
                    </span>
                    <span className="pl-2 text-base text-bodyColor font-primaryRegular">
                      Show Co Signer
                    </span>
                  </label>
                  {showCoSigner && (
                    <>
                      <div className="flex flex-col gap-6">
                        <div className="relative col-span-2 2md:col-span-2">
                          <input
                            type="text"
                            placeholder="Co Signer Name"
                            {...coSignerForm.getFieldProps("co_signer_name")}
                            name="co_signer_name"
                            title="Enter Co Signer Name"
                            className=" text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white focus:outline-none p-3 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                          />
                          {coSignerForm.touched.co_signer_name &&
                          coSignerForm.errors.co_signer_name ? (
                            <span className="absolute  -bottom-4  block text-xs text-red-600">
                              {coSignerForm.errors.co_signer_name}
                            </span>
                          ) : null}
                        </div>

                        <div className="relative col-span-2 2md:col-span-2">
                          <input
                            type="text"
                            placeholder="Co Signer Email"
                            {...coSignerForm.getFieldProps("co_signer_email")}
                            name="co_signer_email"
                            title="Enter Co Signer Email"
                            className=" text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white focus:outline-none p-3 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                          />
                          {coSignerForm.touched.co_signer_email &&
                          coSignerForm.errors.co_signer_email ? (
                            <span className="absolute  -bottom-4  block text-xs text-red-600">
                              {coSignerForm.errors.co_signer_email}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              <div
                className={`relative col-span-2  ${
                  showSidebarPopup ? " 2md:col-span-2" : " 2md:col-span-1"
                }`}
              >
                <div
                  title="Selected Language"
                  className="bg-gray-50 text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white focus:outline-none p-3 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                >
                  English
                </div>
              </div>
              {showSiteSurveyField ? (
                <>
                  <div className="relative col-span-2">
                    <h1 className="sm:text-lg text-titleColor font-primaryMedium mb-4 leading-6">
                      Would you like to do the self site-survey and{" "}
                      <span className="font-primarySemiBold"> receive a</span>{" "}
                      <span className="text-primary font-primarySemiBold">
                        {`${formatCurrency(selfSiteSurveyDiscount)} Discount`}
                      </span>
                      ? <span className="font-primarySemiBold"> Choose</span> an
                      option below to proceed.
                    </h1>
                  </div>
                  <div
                    className={`relative pb-0 xxs:pb-6 col-span-2 ${
                      showSidebarPopup ? " 2md:col-span-2" : " 2md:col-span-1"
                    }`}
                  >
                    <select
                      {...formik.getFieldProps("survey")}
                      name="survey"
                      className="bg-white text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white focus:outline-none p-3 border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                    >
                      <option
                        className="bg-white text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white focus:outline-none border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                        value="1"
                      >
                        Self-Site Survey
                      </option>
                      <option
                        className="bg-white text-sm xxs:text-base focus:ring-2 focus:ring-titleColor focus:ring-offset-white focus:outline-none border border-gray-300 rounded w-full placeholder-current text-bodyColor"
                        value="2"
                      >
                        Schedule representative for site survey
                      </option>
                    </select>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            { isDifferentEnergies ?
              <p className="text-xs leading-tight xxs:text-sm xxs:leading-tight text-bodyColor opacity-90 xxs:line-height2">
                To help you go solar, we need your approval to contact you. By 
                clicking "Unlock Now", you agree that {getOrgName()} may call & text you 
                about {getOrgName()} products at the phone number you entered above, even 
                if your number is on a "Do Not Call" list. Msg & data rates may apply to text messages. Consent 
                for calls & texts is optional. You can opt-out anytime by calling 1-855-DIFFRNT.
              </p>
              :
              <p className="text-xs leading-tight xxs:text-sm xxs:leading-tight text-bodyColor opacity-90 xxs:line-height2">
                To help you go solar, we need your approval to contact you. By
                clicking above, you agree that {getOrgName()} may call & text you
                about <span>{getOrgName()} </span> products at the phone number
                you entered above, using pre-recorded messages or an autodialer,
                even if your number is on a "Do Not Call" list. Msg & data rates
                may apply to text messages. Consent for calls & texts is optional.
                You can opt-out anytime.{" "}
              </p>
            }
          </div>
        </div>
        <div className="px-4 md:px-6 py-4 flex flex-row gap-4 items-center sticky bottom-0 bg-white z-10 justify-end">
          <button
            type="submit"
            className="disabled:opacity-50 block p-3 px-8 rounded bg-primary text-white text-sm font-primaryRegular uppercase text-center hover:shadow hover:bg-opacity-80 w-full md:w-auto"
            disabled={
              !formik.dirty ||
              !formik.isValid ||
              (showCoSigner && (!coSignerForm.dirty || !coSignerForm.isValid))
            }
          >
            Unlock Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default LeadFormModal;
